<template>
  <transition name="fade">
    <div id="back-to-top" v-if="isShowToTop">
      <a href="#"><img src="../assets/images/back-top.png" alt=""/></a>
      <div>返回顶部</div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ToTop",
  data() {
    return {
      isShowToTop: false
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > window.innerHeight) {
        this.isShowToTop = true;
      } else {
        this.isShowToTop = false;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
#back-to-top {
  position: fixed;
  width: 74px;
  height: 120px;
  right: 54px;
  bottom: 54px;
  z-index: 100;
  text-align: center;
  line-height: 75px;
  cursor: pointer;
  a {
    display: block;
    width: 75px;
    height: 75px;
    background-color: #004989;
    border-radius: 50%;
    color: #fff;
    // transition: all test1 0.3s;
  }
  a:hover {
    // transform: scale(1.1);
    animation-name: scale1;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-direction: alternate;
  }
  div {
    color: #000;
  }
}
@keyframes scale1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
