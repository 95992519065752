<template>
  <div id="about">
    <div class="banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>关于我们</div>
        <div>ABOUT US</div>
      </div>
      <SubNav :navList="navList" />
    </div>
    <router-view></router-view>
    <ToTop />
  </div>
</template>

<script>
import SubNav from "@/components/SubNav";

import ToTop from "@/components/ToTop.vue";

export default {
  name: "About",
  components: {
    SubNav,
    ToTop
  },
  data() {
    return {
      navList: [
        { name: "我们是谁", path: "/about-us/whoweare", component: "WhoWeAre" },
        { name: "发起人", path: "/about-us/council", component: "Council" }
        // {
        //   name: "社会影响",
        //   path: "/about-us/influence",
        //   component: "Influence"
        // },
        // { name: "加入我们", path: "/about-us/join-us", component: "JoinUs" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
a[id] {
  position: absolute;
  top: -120px;
}
.nav-reference {
  width: 1250px;
  margin: 0 auto;
  position: relative;
  // border: 1px solid red;
  scroll-behavior: smooth;
}

#about {
  // text-align: center;
  color: #fff;
  width: 100%;
  scroll-behavior: smooth;
}
.banner {
  text-align: center;
  width: 80vw;
  min-width: 1240px;
  max-width: 100%;
  height: 23vw;
  min-height: 340px;
  max-height: 430px;
  margin: 0 auto;
  background-image: url(../../assets/images/two-banner.jpeg);
  background-size: cover;
  background-position: 50% 35%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.267);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      font-size: 40px;
      margin-bottom: 30px;
    }
    div:last-child {
      font-size: 20px;
    }
  }
}
</style>
